@mixin container {
    margin: auto;
    width: 90%;
    max-width: 1100px;
}

@mixin card-element {
    padding-left: 2rem;
    padding-right: 2rem;
}

@mixin inline-spacer {
    margin: auto 1rem;
}
