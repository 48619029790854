@import '@amzn/awsui-design-tokens/polaris.scss';

.class-header {
    padding: 1.5rem;
    background-color: $color-background-dropdown-item-default;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.class-header__link-group {
    display: inline-block;
    vertical-align: top;
    font-size: 16px;
    margin-left: 2.5rem;
    padding: 1.1rem 0;

    a {
        font-size: 16px !important;
        margin: 0 1rem;
        text-decoration: none;
        color: $color-text-body-secondary;
    }
}
