@import '@amzn/awsui-design-tokens/polaris.scss';

.banner-header {
    padding: 64px 0;
    background-color: $color-background-home-header;

    &.with-breadcrumbs {
        padding: 24px 0 64px;
    }

    h1 {
        font-size: 4rem;
    }
}

.banner-content {
    width: 90%;
    margin: auto;
    max-width: 1100px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: row;
}

.landingpage-banner-content,
.banner-breadcrumbs {
    width: 90%;
    margin: auto;
    max-width: 1100px;
}

.awsui .banner-title {
    color: $color-background-control-default;
    font-weight: 500;
    max-width: 85%;
}

.banner-content-button {
    align-self: center;
    float: right;
    margin-left: auto;
    margin-right: 0;
}

.awsui .landingpage-banner-title {
    color: $color-background-control-default;
    font-weight: 500;
}
