.awsui .hero-banner__title {
    color: white;
}

.class-button {
    margin: 0.5rem;
}

.class-header__btns {
    @media screen and (min-width: 768px) {
        text-align: right;
    }
}