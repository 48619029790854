@import '@amzn/awsui-design-tokens/polaris.scss';

.class-error-container__empty-banner {
    min-height: 15rem;
    background-color: $color-background-home-header;

    & + section {
        width: 90vw;
        max-width: 600px;
        min-height: 50vh;
        margin: -3.5rem auto 1rem;
    }
}

.class-error-container_btn-spacer {
    margin: 0.5rem;
    display: inline-block;
    padding: 1px;
}
