@import '@amzn/awsui-design-tokens/polaris.scss';
@import '../container.scss';

.class-card-subheader {
    @include card-element();
    margin: 0;

    &.active {
        color: green;
    }

    &.upcoming {
        color: blue;
    }

    .status {
        margin-left: 0.5rem;
    }
}

.class-card-subheader + p {
    margin-top: 0;
}

.class-card__too-early-warning {
    margin-right: 1.5rem;
}
