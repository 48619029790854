@import '../container.scss';

.student-landing-container {
    @include container();
    margin-top: -5rem;
    position: relative;
    z-index: 2;
}

.no-classes-found-container {
    @include container();
    margin: 0 auto;
}
