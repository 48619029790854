@import '../container.scss';

.class-meta-container {
    @include container();
    margin-top: -5rem;
    margin-bottom: 0;
    position: relative;
    z-index: 2;
}

.class-lab-list-container {
    @include container();
    margin-top: 2rem;

    & > [data-testid='section-wrapper'] {
        margin-top: 32px;
    }

    & ~ .class-lab-list-container {
        margin-top: 0;

        h2,
        [data-testid='section-wrapper'] {
            margin-top: 0;
        }
    }
}
